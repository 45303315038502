<template>
  <v-card elevation="0" outlined class="card-border">
    <v-card-title>
      <v-row dense>
        <v-col cols="auto" class="title">
          {{title}}
        </v-col>
        <v-spacer />
        <v-col cols="" class="justify-end"><v-select dense v-model="options.hours" :items="vs.ersQueryHourOptions" /></v-col>
        <v-col cols="" class="justify-end"><v-select dense v-model="options.custCode" :items="customerOptions" /></v-col>
        <v-col cols="" class="justify-end"><v-text-field dense v-model="options.plateNo" placeholder="차량번호 검색"></v-text-field> </v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="primary" @click="options.status = null">전체</v-btn></v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="info" @click="options.status = 'NA'">미배정</v-btn></v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="primary" @click="options.status = 'ND'">출동전</v-btn></v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="info" @click="options.status = 'DP'">출동</v-btn></v-col>
        <v-col cols="auto" class="justify-end"><v-btn small width="80" color="primary" @click="options.status = 'TM'">초과</v-btn></v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-data-table disable-filtering disable-sort single-select dense
                  :items="items" :headers="headers"
                  :loading="loading" :items-per-page="5" item-key="no"
                  mobile-breakpoint="0" :footer-props="vs.footerPropsErsTable"
                  @click:row="selectItem"
      >
        <template v-slot:item.ersDts="{ item }">
          {{moment(item.ersDts,'YYYYMMDDhhmmss').format('YYYY/MM/DD HH:mm')}}
        </template>
        <template v-slot:item.vehicleKind="{ item }">
          <v-row no-gutters>{{item.isVip ? '[V] ' : ''}} {{item.vehicleKind}}</v-row>
          <v-row no-gutters>{{item.plateNo}}</v-row>
        </template>
        <template v-slot:item.reqLocation="{ item }">
          <v-row no-gutters>{{item.isHighway ? '[H] ' : ''}} {{item.addrBase}}</v-row>
          <v-row no-gutters>{{item.addrDetail}}</v-row>
        </template>
        <template v-slot:item.comment="{ item }">
          <v-tooltip v-if="item.comment" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-bell</v-icon>
            </template>
            <span>{{item.comment}}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.assign="{ item }">
          {{vs.renderOpName(item.opName)}}
        </template>
        <template v-slot:item.elapsed="{ item }">
          <span v-if="item.elapsed && item.taskStatus !== 'TE' && item.taskStatus !== 'AR' && item.taskStatus !== 'TS' " style="padding:2px;" :style="elapsedStyle(item.elapsed)">{{item.elapsed}} 분</span>
        </template>
        <template v-slot:item.taskStatus="{ item }">
          <v-chip small>{{vs.renderTaskStatus(item.taskStatus)}}</v-chip>
        </template>
        <template v-slot:item.payCode="{item}">
          {{vs.renderPayCode(item.payCode)}}
        </template>
        <template v-slot:item.req="{item}">
          <v-tooltip v-if="item.hasAgentReq && item.agentReq" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="red">mdi-bell</v-icon>
            </template>
            <span>{{item.agentReq.message}}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import vs from '@/utils/viewSupport'
import api from '@/services/api'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'ErsTable',
  props: {
    title: { type: String, default: '' },
    customers: { type: Array, default: () => { return [] } },
  },
  data() {
    return {
      vs,
      moment,
      items: [],
      headers: [
        { text: '접수#', value: 'ersId' },
        { text: '일시', value: 'ersDts' },
        { text: '접수번호' , value: 'ersKey'},
        { text: '차종', value: 'vehicleKind' },
        { text: '위치', value: 'reqLocation' },
        { text: '요청', value: 'reqMessage' },
        { text: '유무상', value: 'payCode' },
        { text: '전달', value: 'comment' },
        { text: '가맹점|기사', value: 'assign' },
        { text: '', value: 'req' },
        { text: '상태', value: 'taskStatus' },
        { text: '경과시간', value: 'elapsed' },
        { text: '처리자', value: 'opName.counselor' },
      ],
      options: {
        custCode: null,
        hours: null,
        status: null,
        plateNo: null,
      },
      loading: false,
      timer: null,
    }
  },
  methods: {
    list() {
      this.loading = true
      api.ersRequestQuery(this.options).then(r => {
        this.updateElapsed(r.result)
        this.items = r.result
      }).finally(() => { this.loading = false })
    },
    selectItem(item) {
      this.$emit('select', this.custCode, item)
    },
    updateElapsed(arr = this.items) {
      arr.forEach(e => {
        if (e.taskStatus !== 'NA' && e.allocAt) {
          e.elapsed = moment().diff(moment(e.allocAt), 'minutes')
        }
      })
    },
    elapsedStyle(elapsed) {
      if (elapsed > 10) return 'background-color:red;color:white;font-weight:bold;'
      return ''
    }
  },
  mounted() {
    this.list()
    if (this.timer) clearInterval(this.timer)
    this.timer = setInterval(this.updateElapsed, 1000 * 60)
  },
  computed: {
    ...mapGetters(['newRequest']),
    customerOptions() {
      const cust = this.customers.map(e => { return { text: e.custName, value: e.custCode } })
      cust.splice(0,0, { text: '전체', value: null })
      return cust
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
       this.list()
      }
    },
    newRequest() {
      this.list()
    }
  }
}
</script>

<style scoped>
.v-data-table >>> thead tr th {
  padding: 6px !important;
  font-weight: bold !important;
  background-color: black !important;
  color: white !important;
  font-size: 12px !important;
}

.v-data-table >>> tbody tr td {
  padding: 0px 0px 0px 0px !important;
  font-size: 11px !important;
}

>>> div.v-select__selections > div {
  font-size:14px !important;
}

.title {
  font-weight: bold;
}

.card-border {
  border: 2px solid black;
}
</style>
